import React, { Component } from 'react';

import firebase from '../Firebase';
import { Link } from 'react-router-dom';

class Create extends Component {

  constructor() {
    super();
    this.ref = firebase.firestore().collection('boards');
    this.state = {
      title: '',
      description: '',
      author: '',
      date: '', name: '', email: '', address: '', user_id: '', fenceData: '',  phone: '',
      usercomment: ''
    };
  }
  onChange = (e) => {
    const state = this.state
    //state['date'] = firebase.firestore.FieldValue.serverTimestamp()
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  onSubmit = (e) => {
    const state = this.state


    state['date'] = firebase.firestore.FieldValue.serverTimestamp()

    state[e.target.name] = e.target.value;
    e.preventDefault();

    const { title, description, author, date, name, email, address, user_id, fenceData, phone, usercomment } = this.state;

    this.ref.add({
      title,
      description,
      author, 
      date, name, email, address, user_id, fenceData, phone, usercomment
    }).then((docRef) => {
      this.setState({
        title: '',
        description: '',
        author: '',
        date: '', name: '', email: '', address: '', user_id: '', fenceData: '',  phone: '',
        usercomment: ''
      });
      this.props.history.push("/")
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
  }

  render() {
    const { title, description, author, date, name, email, address, user_id, fenceData, phone, usercomment } = this.state;
    return (
      <div class="container">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              ANFRAGE HINZUFÜGEN
            </h3>
          </div>
          <div class="panel-body">
            <h4><Link to="/" class="btn btn-primary">Anfragen anzeigen</Link></h4>
            <form onSubmit={this.onSubmit}>
              <div class="form-group">
                <label for="title">Title:</label>
                <input type="text" class="form-control" name="title" value={title} onChange={this.onChange} placeholder="Title" />
              </div>
              <div class="form-group">
                <label for="description">Description:</label>
                <textArea class="form-control" name="description" onChange={this.onChange} placeholder="Description" cols="80" rows="3">{description}</textArea>
              </div>
              <div class="form-group">
                <label for="author">Author:</label>
                <input type="text" class="form-control" name="author" value={author} onChange={this.onChange} placeholder="Author" />
              </div>
              {/*<div class="form-group">
                <label for="date">Datum:</label>
                <input type="text" class="form-control" name="date" value={date} onChange={this.onChange} placeholder="Datum" />
              </div>*/}
              <div class="form-group">
                <label for="name">Name:</label>
                <input type="text" class="form-control" name="name" value={name} onChange={this.onChange} placeholder="name" />
              </div>
              <div class="form-group">
                <label for="email">Email:</label>
                <input type="text" class="form-control" name="email" value={email} onChange={this.onChange} placeholder="email" />
              </div>
              <div class="form-group">
                <label for="address">Adresse:</label>
                <textArea class="form-control" name="address" onChange={this.onChange} placeholder="Adresse" cols="80" rows="3">{address}</textArea>
               </div>
               <div class="form-group">
                <label for="phone">Telefon:</label>
                <input type="phone" class="form-control" name="phone" value={phone} onChange={this.onChange} placeholder="phone" />
              </div>
              <div class="form-group">
                <label for="usercomment">Bemerkungen:</label>
                <textArea class="form-control" name="usercomment" onChange={this.onChange} placeholder="Bemerkungen" cols="80" rows="8">{usercomment}</textArea>
            
              </div>
              <div class="form-group">
                <label for="user_id">User ID:</label>
                <input type="text" class="form-control" name="user_id" value={user_id} onChange={this.onChange} placeholder="user_id" />
              </div>
              <div class="form-group">
                <label for="fenceData">Fence Data:</label>
                <input type="text" class="form-control" name="fenceData" value={fenceData} onChange={this.onChange} placeholder="fenceData" />
              </div>
              <button type="submit" class="btn btn-success">Submit</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Create;
