import React, { Component } from 'react';
import { compose, withProps } from "recompose"
import firebase from '../Firebase';
import { Link } from 'react-router-dom';
import moment from 'moment'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
} from "react-google-maps";
import { DataManager } from '../DataManager';

var path = require('path');
const MyMapComponent = withScriptjs(withGoogleMap(props =>
  <GoogleMap
    zoom={props.zoom}
    defaultZoom={props.defaultZoom}
    defaultCenter={props.defaultCenter}
    center={props.center}
    coordinates_gm={props.coordinates_gm}
  >
    {props.markers.map(marker => (
      <Marker
        key={marker[2]}
        position={{ lat: marker[0], lng: marker[1] }}
      />


    ))}


    <Polyline
      path={props.coordinates_gm}

      geodesic={true}
      options={{
        strokeColor: "#000",
        strokeOpacity: 0.75,
        strokeWeight: 5
      }}
    />



    {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
  </GoogleMap>
))


class Show extends Component {



  textureUrl = path.join(__dirname, './assets/data/textures')
  
  constructor(props) {
   
    super(props);
  
    console.log("constructor: SHOW")
    this.state = {
      board: {},
      fenceData: JSON,
      key: '',
      location: '',
      defaultCenter: { lat: -34.397, lng: 150.644 },
      center: { lat: -34.397, lng: 150.644 },
      defaultZoom: 0,
      zoom: 200,
      isMarkerShown: false,
      fenceHeight: 0,
      fenceType: 0,
      markers: [],
      markersGM: [],
      userProjectWarningOpen:false,
      userNotFoundWarningOpen:false,

      
    };
 
  }

  componentDidMount() {
    console.log("componentDidMount: SHOW")
    this.checkProjectId()

    const ref = firebase.firestore().collection('boards').doc(this.props.match.params.id);

   

    ref.get().then((doc) => {
      if (doc.exists) {
        const board = doc.data();
        var fenceData = {}
        var center = {}
        var markersGM = []
        var fenceHeight = 0
        var fenceType = 0
        var markers = []
        var variation = ""
        if (board.fenceData.length > 0) {
          fenceData = JSON.parse(board.fenceData)
          center = { lat: parseFloat(fenceData.coordinates.fence[1][0]), lng: parseFloat(fenceData.coordinates.fence[1][1]) }
          fenceHeight = fenceData.config.fence.height
          markers = fenceData.coordinates.fence
          markersGM = fenceData.coordinates_gm.fence
          fenceType = fenceData.config.fence.fenceType
          variation = fenceData.config.fence.variation
          for (var i = 0; i < fenceData.coordinates.fence.length; i++) {
            var m = fenceData.coordinates.fence[i]
            console.log("MARKER: " + m[2])
          }
        }

        this.setState({
          board: board,
          fenceData: fenceData,
          key: doc.id,
          isLoading: false,
          center: center,
          defaultCenter: { lat: -34.397, lng: 150.644 },
          defaultZoom: 1,
          isMarkerShown: true,
          zoom: 19,
          fenceHeight: fenceHeight,
          fenceType: fenceType,
          markers: markers,
          markersGM: markersGM,
          variation:variation
        }, () => {

        });



      } else {
        console.log("No such document!");

        this.openNotFoundWarning()
      }
    });
  }

  delete(id) {
    /* firebase.firestore().collection('boards').doc(id).delete().then(() => {
        console.log("Document successfully deleted!");
        this.props.history.push("/")
      }).catch((error) => {
        console.error("Error removing document: ", error);
      });*/
    alert("NOT AVTIVE")

  }

  getFenceImageSrc = () => {
        
    var folder = ""+this.state.fenceType
    var variation =  this.state.variation
    var imageSrc = this.textureUrl + "/" + folder + "/" + variation + "/element.png"
    
    return  imageSrc

  }

  onOpenLink = () => {
//https://planer.zaunplaner.services?${this.state.key

window.open(
  'https://planer.zaunplaner.services?fb='+this.state.key,
  '_blank' // <- This is what makes it open in a new window.
);
  }

   handleClickOpen = () => {
    this.setState({
      userProjectWarningOpen:true
    })
  }

   handleClose = () =>  {
    this.setState({
      userProjectWarningOpen:false
    })

    this.onOpenLink()
  }


  openNotFoundWarning = () => {
    this.setState({
      userNotFoundWarningOpen:true
    })
  }

   handleCloseNotFoundWarning = () =>  {
    this.setState({
      userNotFoundWarningOpen:false
    })

    this.props.history.push('/')
  }

  checkProjectId = () => {
    console.log("CHECK PROJECT ID: "+this.props.match.params.id)
    if(this.props.match.params.id == null || this.props.match.params.id === undefined || this.props.match.params.id < 7) {
      //this.setState({ loginDialogOpen: false });
      this.props.history.push('/')
    }

  };


  render() {
    const fendeDataLoaded = this.state.board.email;
    return (


      <div class="container">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h4><Link to="/" class="btn btn-primary">Zurück zur Anfragen Liste</Link>    <button onClick={this.handleClickOpen} class="btn btn-danger">Kundenansicht öffnen</button>&nbsp;</h4>
            <h3 class="panel-title">
              {this.state.board.title}
            </h3>
          </div>
          <div class="panel-body">
            <dl>
             {/* <dt>Bearbeiter:</dt>
              <dd>{this.state.board.author}</dd>
              <dt>Notizen:</dt>
              <dd>{this.state.board.description}</dd>
              
              {/*<dt>user_id:</dt>
              <dd>{this.state.board.user_id}</dd>*/}
              <dt>Datum:</dt>
              <dd>{DataManager.getFormattedDate(this.state.board.date)}</dd>
              <dt>Name:</dt>
              <dd>{this.state.board.name}</dd>
              <dt>Telefon:</dt>
              <dd>{this.state.board.phone}</dd>
              <dt>Bemerkungen:</dt>
              <dd>{this.state.board.usercomment}</dd>
              <dt>email:</dt>
              <dd><a href={`mailto:${this.state.board.email}`}>{this.state.board.email}</a></dd>
              <dt>Adresse:</dt>
              <dd>{this.state.board.address}</dd>
              <dt>Strasse:</dt>
              <dd>{this.state.board.street}</dd>
              <dt>Ort:</dt>
              <dd>{this.state.board.city}</dd>
              <dt>PLZ:</dt>
              <dd>{this.state.board.plz}</dd>
              <dt>Country:</dt>
              <dd>{this.state.board.country}</dd>
              <dt>Zaundaten:</dt>
              <dd>Höhe: {this.state.fenceHeight} Zauntyp: {this.state.fenceType}</dd>
              
            </dl>
         
            {/*<button onClick={this.delete.bind(this, this.state.key)} class="btn btn-danger">Delete</button> <Link to={`https://planer.zaunplaner.services?${this.state.key}`} class="btn btn-success">Kundenansicht öffnen</Link>*/}
          </div>
          <img src={this.getFenceImageSrc()} alt="Selected Fence" height="80px"/>
        </div>
        <div class="GoogleMapContainer">
          <MyMapComponent

            isMarkerShown={this.state.isMarkerShown}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCf5VarQ3amrnPXN2xg7HsQFXJgvhToWZU&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `250px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            defaultZoom={this.state.defaultZoom}
            defaultCenter={this.state.defaultCenter}
            center={this.state.center}
            zoom={this.state.zoom}
            markers={this.state.markers}
            coordinates_gm={this.state.markersGM}
          />

        </div>

        <Dialog
        open={this.state.userProjectWarningOpen}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"KundenProjekt öffnen"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
        Das Kundenprojekt wird geöffnet. Bitte beachten, das Änderungen gespeichert werden und das KundenprojektM dadurch verändert wird.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={this.handleClose} color="primary" autoFocus>
          Kundenprojekt öffnen
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={this.state.userNotFoundWarningOpen}
        onClose={this.handleCloseNotFoundWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"UProjekt nicht gefunden"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Ein Projekt mit der ID {this.state.key} nicht gefunden! Überprüfen sie bitte die Projekt ID und geben sie eine korrekte ID ein.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
    
          <Button onClick={this.handleCloseNotFoundWarning} color="primary" autoFocus>
            zurück
          </Button>
        </DialogActions>
      </Dialog>

      </div>

    );
  }
}

export default Show;
