import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import firebase from './Firebase';

import {DataManager} from './DataManager'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { dispatch } from '../../../../Library/Caches/typescript/3.5/node_modules/rxjs/internal/observable/pairs';



class App extends Component {
  constructor(props) {
    console.log("CONSTRUCTOR: APP")
    super(props);
    this.ref = firebase.firestore().collection('boards').orderBy('date', 'desc');
    this.unsubscribe = null;
    this.state = {
      boards: [],
      loginDialogOpen: false,
      selectedProjectId:"",
    };
  }

  onCollectionUpdate = (querySnapshot) => {
    const boards = [];
    querySnapshot.forEach((doc) => {
      const { title, description, author, date, name, email, address, user_id, fenceData } = doc.data();
      boards.push({
        key: doc.id,
        doc, // DocumentSnapshot
        title,
        description,
        author,
        date, name, email, address, user_id, fenceData,
      });
    });
    this.setState({
      boards
   });
  }

  componentDidMount() {
    console.log("componentDidMount: APP")
    this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    this.readAndProccessUrl()
  }
  
  delete(id){
    firebase.firestore().collection('boards').doc(id).delete().then(() => {
      console.log("Document successfully deleted!");
      this.props.history.push("/")
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });
  }

  readAndProccessUrl  = () => {

    console.log("ID: " + window.location.pathname)
    this.setState({loginDialogOpen:true})
  /*  var pathname = window.location.pathname

    if (pathname != null && pathname !== undefined) {



    } else {
  
    }*/

  }

  handleClickOpen = () => {
    console.log("OPEN")
    this.setState({ loginDialogOpen: true });
  };

  handleLoginTextFieldChange = (event) => {

    this.setState({ selectedProjectId: event.target.value });

  };

  handleLoadProjectId = () => {
    if(this.state.selectedProjectId.length != null && this.state.selectedProjectId.length > 7) {
      //this.setState({ loginDialogOpen: false });
      this.props.history.push("/show/"+this.state.selectedProjectId)
    } else {
      
    }

  };

  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      });
    return vars;
  }
  render() {
 
    return (
      <div className="container">
  

        <div class="panel panel-default">
       
          <div class="panel-body">
            <h4><Link to="/create" class="btn btn-primary">Anfrage hinzufügen</Link></h4>
            <table class="table table-stripe">
              <thead>
                <tr>
                  <th>Datum</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Bearbeiter</th>
                  <th>Löschen</th>
                </tr>
              </thead>
              <tbody>
                {/*this.state.boards.map(board =>
                  <tr>
                    <td><Link to={`/show/${board.key}`}>{DataManager.getFormattedDate(board.date)}</Link></td>
                    <td><Link to={`/show/${board.key}`}>{board.name}</Link></td>
                    <td>{board.email}</td>
                    <td>{board.author}</td>
                    <td><button onClick={this.delete.bind(this, board.key)} class="btn btn-danger">x</button></td>
                  </tr>
                )*/}
              </tbody>
            </table>
          </div>
        </div>

        <Dialog
          open={this.state.loginDialogOpen}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
    
          <DialogTitle id="form-dialog-title">Projekt laden</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Bitte die  Projekt ID eingeben.
            </DialogContentText>
            <TextField
              onChange={this.handleLoginTextFieldChange}
              autoFocus
              margin="dense"
              id="name"
              label="Projekt ID"
              type="text"
              fullWidth
            />
       
          </DialogContent>
          <DialogActions>
            <Button class="form-dialog-wide-button" onClick={this.handleLoadProjectId} color="primary">
              Laden
            </Button>
          </DialogActions>
     
          <DialogActions>
            <Button class="form-dialog-wide-button" id="cancel-button" onClick={this.handleCancelLoad} >
              Abbrechen
            </Button>
       
            </DialogActions>
        </Dialog>

      </div>
    );
  }
}

export default (App);
