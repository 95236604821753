import React, { Component } from 'react';
import firebase from '../Firebase';
import { Link } from 'react-router-dom';
import { DataManager } from '../DataManager';

class Edit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      key: '',
      title: '',
      description: '',
      author: '',
      street: '',
      plz: '',
      city: '',
      country: '',
      date: '', name: '', email: '', address: '', user_id: '', fenceData: '',  phone: '',
      usercomment: ''
    };
  }

  componentDidMount() {
    const ref = firebase.firestore().collection('boards').doc(this.props.match.params.id);
    ref.get().then((doc) => {
      if (doc.exists) {
        const board = doc.data();
        this.setState({
          key: doc.id,
          title: board.title ? board.title:"",
          description: board.description ? board.description:"",
          author: board.author ? board.author:"",
          date: board.date, 
          name: board.name ? board.name:"", 
          email: board.email ? board.email:"", 
          address: board.address ? board.address:"", 
          street: board.street ? board.street: "",
          plz: board.plz ? board.plz: "",
          city: board.city ? board.city: "",
          country: board.country ? board.country: "",
          user_id: board.email ? board.email:"", 
          fenceData: board.fenceData ? board.fenceData:"",
          phone:board.phone ? board.phone:"",
          usercomment:board.usercomment ? board.usercomment:"",
        });
      } else {
        console.log("No such document!");
      }
    });
  }

  onChange = (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState({board:state});
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { title, description, author,  name, email, address, user_id, fenceData, phone, usercomment } = this.state;

    const updateRef = firebase.firestore().collection('boards').doc(this.state.key);
    updateRef.set({
      title, description, author, name, email, address, user_id, fenceData, phone, usercomment
    }).then((docRef) => {
      this.setState({
        key: '',
        title: '',
        description: '',
        author: '',
        street: '',
        plz: '',
        city: '',
        country: '',
        date: '', name: '', email: '', address: '', user_id: '', fenceData: '',  phone: '',
        usercomment: ''
      });
      this.props.history.push("/show/"+this.props.match.params.id)
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
  }

  render() {
    return (
      <div class="container">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h3 class="panel-title">
              ANFRAGEN BEARBEITEN 
            </h3>
          </div>
          <div class="panel-body">
            <h4><Link to={`/show/${this.state.key}`} class="btn btn-primary">Anfrage Details</Link></h4>
            <form onSubmit={this.onSubmit}>
              <div class="form-group">
                <label for="title">Title:</label>
                <input type="text" class="form-control" name="title" value={this.state.title} onChange={this.onChange} placeholder="Title" />
              </div>
              <div class="form-group">
                <label for="description">Notizen:</label>
                <textArea class="form-control" name="description" onChange={this.onChange} placeholder="Notizen" cols="80" rows="3">{this.state.description}</textArea>
              </div>
              <div class="form-group">
                <label for="author">Bearbeiter:</label>
                <input type="text" class="form-control" name="author" value={this.state.author} onChange={this.onChange} placeholder="Author" />
              </div>
              <div class="form-group">
                <label for="date">Datum:</label>
                <label name="date">{DataManager.getFormattedDate(this.state.date)}</label>
              </div>
              <div class="form-group">
                <label for="name">Name:</label>
                <input type="text" class="form-control" name="name" value={this.state.name} onChange={this.onChange} placeholder="name" />
              </div>
              <div class="form-group">
                <label for="email">Email:</label>
                <input type="text" class="form-control" name="email" value={this.state.email} onChange={this.onChange} placeholder="email" />
              </div>
              <div class="form-group">
                <label for="phone">Telefon:</label>
                <input type="phone" class="form-control" name="phone" value={this.state.phone} onChange={this.onChange} placeholder="phone" />
              </div>
              <div class="form-group">
                <label for="usercomment">Bemerkungen:</label>
                <textArea class="form-control" name="usercomment" onChange={this.onChange} placeholder="Bemerkungen" cols="80" rows="8">{this.state.usercomment}</textArea>
            
              </div>
              <div class="form-group">
                <label for="address">Adresse:</label>
                <textArea class="form-control" name="address" onChange={this.onChange} placeholder="Adresse" cols="80" rows="3">{this.state.address}</textArea>
               </div>
               <div class="form-group">
                <label for="street">Strasse:</label>
                <input type="text" class="form-control" name="street" value={this.state.street} onChange={this.onChange} placeholder="strasse" />
              </div>

              <div class="form-group">
                <label for="plz">PLZ:</label>
                <input type="text" class="form-control" name="plz" value={this.state.plz} onChange={this.onChange} placeholder="plz" />
              </div>

              <div class="form-group">
                <label for="city">Ort:</label>
                <input type="text" class="form-control" name="city" value={this.state.city} onChange={this.onChange} placeholder="ort" />
              </div>


              <div class="form-group">
                <label for="country">Land:</label>
                <input type="text" class="form-control" name="country" value={this.state.country} onChange={this.onChange} placeholder="Land" />
              </div>



              <div class="form-group">
                <label for="user_id">User ID:</label>
                <input type="text" class="form-control" name="user_id" value={this.state.user_id} onChange={this.onChange} placeholder="user_id" />
              </div>
              <div class="form-group">
                <label for="fenceData">Fence Data:</label>
                <textArea class="form-control" name="fenceData" onChange={this.onChange} placeholder="data" cols="80" rows="3">{this.state.fenceData}</textArea>
             </div>
              <button type="submit" class="btn btn-success">Submit</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Edit;
