export class DataManager {
static   getFormattedDate(timestamp){
    if (timestamp != null && timestamp !== "") {
        var date = timestamp.toDate()
    console.log(date.toLocaleDateString)
    return date.toLocaleDateString()+" - "+date.toLocaleTimeString()
    }
return ""
    
  }
}