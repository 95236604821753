import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'

const settings = {timestampsInSnapshots: true};

/*const config = {
  apiKey: "AIzaSyBcBUgp0WJYOcPZjRPaY_-qS8ZnE0dxXMw",
  authDomain: "training01-26482.firebaseapp.com",
  databaseURL: "https://training01-26482.firebaseio.com",
  projectId: "training01-26482",
  storageBucket: "training01-26482.appspot.com",
  messagingSenderId: "560170724335"
};*/
const config = {
  apiKey: "AIzaSyCFuhAVeEaRFZXfxtCJ8ZhU15cnf0bOSUw",
  authDomain: "zaunplaner-1525772594816.firebaseapp.com",
  databaseURL: "https://zaunplaner-1525772594816.firebaseio.com",
  projectId: "zaunplaner-1525772594816",
  storageBucket: "zaunplaner-1525772594816.appspot.com",
  messagingSenderId: "945645339002"
};
firebase.initializeApp(config);

firebase.firestore().settings(settings);

export default firebase;
